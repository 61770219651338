/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route configuration files
 * Dependicies : To use @material-ui/core
 ************/

import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {dashBoardConfigs} from './dashboard';
import {pharmacyConfigs} from './pharmAssistPage';
import {uploadPageConfig} from './doctorUpload/index';
import {labAssistConfigs} from './labAssistPage';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {radiologyConfigs} from './radiologyPage';

const routeConfigs = [
  ...authRouteConfig,
  ...dashBoardConfigs,
  ...pharmacyConfigs,
  ...uploadPageConfig,
  ...labAssistConfigs,
  ...radiologyConfigs
];
const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
