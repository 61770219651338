/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  AUTH_USERID,
  PATIENT_COMPLETED_DATA,
  PATIENT_COMPLETED_PATIENT_NAME,
  PATIENT_COMPLETED_COUNT,
  FORGOT_PASWD_SUCCESS,
  FORGOT_PASWD_ERROR,
  CHECK_MODE,
  FETCH_SUCCESS,
  ERX_IMG_ARR,
  ERX_DATA_HTML,
  ERX_DATA_HTML_NO_DATA,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Patient.js';

//Sign up Patient
export const onSignupPatient = ({mobileNbr, pName, limit, offset}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      pName,
      limit,
      offset,
    };
    try {
      jwtAxios
        .post('v1/patMgmt/patientCompletedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                mobileNbr,
                pName,
                limit,
                offset,
                jwtToken,
              };
              jwtAxios
                .post('v1/patMgmt/patientCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: PATIENT_COMPLETED_COUNT,
                      payload: data.data.body.totalcount,
                    });
                    dispatch({
                      type: PATIENT_COMPLETED_DATA,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onSignupPatient',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onSignupPatient',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSignupPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSignupPatient', '0', err),
      );
    }
  };
};

//Sign up Patient
export const onForgetPassword = ({mobileNbr, newpaswd, otp, isNumber,type}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      newpaswd,
      otp,
      isNumber,
      type
    };
    try {
      jwtAxios
        .post('v1/vendor/forgetpaswdjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              if (data.hasOwnProperty.call(data.data, 'body')) {
                const jwtToken = data.data.body['token'];
                const bodytoken = {
                  mobileNbr,
                  newpaswd,
                  otp,
                  type,
                  jwtToken,
                };
                jwtAxios
                  .post('v1/vendor/forgetpaswd', bodytoken)
                  .then((data) => {
                    if (data.status === 200) {
                      // dispatch({type: FETCH_SUCCESS});                 
                      dispatch({
                        type: FORGOT_PASWD_SUCCESS,
                        payload: 'Your password has been changed successfully',
                      });
                      dispatch({
                        type: CHECK_MODE,
                        payload: '',
                      });
                      dispatch({
                        type: AUTH_USERID,
                        payload: '',
                      });
                      // dispatch({
                      //   type: FORGOT_PASWD_MSG,
                      //   payload: 'Your password has been changed successfully',
                      // });
                    } else {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: 'Invalid OTP',
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onSignupPatient',
                        '0',
                        error,
                      ),
                    );
                  });
              } else {
                if (data.data.hasOwnProperty.call(data.data, 'message')) {
                  if (data.data.message.includes('Error')) {
                    if (data.data.message.includes('2')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('2')[1],
                      });
                    } else if (data.data.message.includes('1')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('1')[1],
                      });
                    } else if (data.data.message.includes('3')) {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: data.data.message.split('3')[1],
                      });
                    } else {
                      dispatch({
                        type: FORGOT_PASWD_ERROR,
                        payload: 'Invalid OTP',
                      });
                    }
                  }
                }
              }
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onSignupPatient',
                  '0',
                  err,
                ),
              );
            }
          } else {
            if (data.hasOwnProperty.call(data, 'message')) {
              if (data.message.includes('Error')) {
                if (data.message.includes('2')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('2')[1],
                  });
                } else if (data.message.includes('1')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.message.split('1')[1],
                  });
                } else if (data.data.message.includes('3')) {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: data.data.message.split('3')[1],
                  });
                } else {
                  dispatch({
                    type: FORGOT_PASWD_ERROR,
                    payload: 'Invalid OTP',
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSignupPatient',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onSignupPatient', '0', err),
      );
    }
  };
};

