/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SEARCH_DISPENSES_PENDING,
  SEARCH_TOTAL_COUNT,
  ERX_IMAGES_ARRAY,
  ERX_DISPENSED_IMAGES_ARRAY,
  SAVE_DISPENSES_MSG,
  SEARCH_LIST_RESP,
  SEARCH_DISPENSED_LIST,
  SEARCH_DISPENSE_LIST_RESP,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Pharmacy.js';

//Update onGetSearchPatientData
export const onGetSearchDispensesData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call onGetSearchPatientData  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/searchClientDispenseJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/searchClientDispenses', value)
              .then((data) => {
                if (data.data.status === 200) {
                  const getdata = data?.data?.body;
                  dispatch({type: FETCH_SUCCESS});
                  if(getdata){
                    
                  dispatch({
                    type: SEARCH_LIST_RESP,
                    payload: data?.data?.status,
                  });
                    dispatch({
                      type: SEARCH_DISPENSES_PENDING,
                      payload: getdata?.data,
                    });
                    dispatch({
                      type: SEARCH_TOTAL_COUNT,
                      payload: getdata?.totalCount,
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'searchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'searchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'searchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'searchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};

//Update onGetSearchPatientData
export const onGetErxImagesData = (value, from = '') => {
  return (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/pateRxMgmt/getErxImages', value)
        .then((data) => {
          if (data.data.status === 200) {
            if (from === 'dispensed') {
              dispatch({
                type: ERX_DISPENSED_IMAGES_ARRAY,
                payload: data.data.body,
              });
            } else {
              dispatch({
                type: ERX_IMAGES_ARRAY,
                payload: data.data.body,
              });
            }
            dispatch({type: FETCH_SUCCESS});
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'searchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'searchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};

//Update onGetSearchPatientData
export const saveMedicineDispense = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call onGetSearchPatientData  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/saveMedicineDispensejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/vendor/saveMedicineDispense', value)
              .then((data) => {
               

                if (data.data.status === 200) {
                  dispatch({
                    type: SAVE_DISPENSES_MSG,
                    payload: data.data.body,
                  });

                  dispatch({type: FETCH_SUCCESS});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch({
                  type: SAVE_DISPENSES_MSG,
                  payload: 'failure',
                });
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'searchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch({
              type: SAVE_DISPENSES_MSG,
              payload: 'failure',
            });
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'searchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({
            type: SAVE_DISPENSES_MSG,
            payload: 'failure',
          });
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'searchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: SAVE_DISPENSES_MSG,
        payload: 'failure',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'searchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};

//Update onGetSearchPatientData
export const onGetSearchDispensedData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call onGetSearchPatientData  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/getDispensedListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/vendor/getDispensedList', value)
              .then((data) => {
                if (data.data.status === 200) {
                  const getdata = data.data.body;

                  dispatch({
                    type: SEARCH_DISPENSED_LIST,
                    payload: getdata,
                  });
                  dispatch({
                    type: SEARCH_DISPENSE_LIST_RESP,
                    payload: data.data.status,
                  });

                  dispatch({type: FETCH_SUCCESS});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'searchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'searchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'searchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'searchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};
