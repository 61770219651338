/************
 * Developed by : Shiva Software Solutions
 * Date : 25-04-2024
 * Descriptions : Call api response using reducers
 ************/
import {
  SEARCH_DISPENSES_PENDING,
  SEARCH_TOTAL_COUNT,
  ERX_IMG_ARR_LST,
  SEARCH_LIST_RESP,
  SAVE_DISPENSES_MSG,
  SEARCH_DISPENSED_LIST,
  ERX_IMAGES_ARRAY,
  ERX_DISPENSED_IMAGES_ARRAY,
  SEARCH_DISPENSE_LIST_RESP,
} from '../../shared/constants/ActionTypes';

const initialState = {
  pendingDispenses: [],
  totalCount:0,
  searchListResp: '',
  saveDispensesMsg: '',
  erximgdatas: [],
  searchDispensedList: [],
  erxImagesArray: [],
  erxImagesDispensedArr: [],
  searchDispenseListResp: '',
};

const pharmacyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DISPENSES_PENDING:
      return {
        ...state,
        pendingDispenses: action.payload,
      };
      case SEARCH_TOTAL_COUNT:
        return {
          ...state,
          totalCount: action.payload,
        };
    case ERX_IMAGES_ARRAY:
      return {
        ...state,
        erxImagesArray: action.payload,
      };
    case ERX_DISPENSED_IMAGES_ARRAY:
      return {
        ...state,
        erxImagesDispensedArr: action.payload,
      };
    case ERX_IMG_ARR_LST:
      return {
        ...state,
        erximgdatas: action.payload,
      };
    case SEARCH_DISPENSE_LIST_RESP:
      return {
        ...state,
        searchDispenseListResp: action.payload,
      };
    case SEARCH_LIST_RESP:
      return {
        ...state,
        searchListResp: action.payload,
      };
    case SAVE_DISPENSES_MSG:
      return {
        ...state,
        saveDispensesMsg: action.payload,
      };
    case SEARCH_DISPENSED_LIST:
      return {
        ...state,
        searchDispensedList: action.payload,
      };
    default:
      return state;
  }
};
export default pharmacyReducer;
