/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  
  PATIENT_COMPLETED_PATIENT_NAME,
 
  FORGOT_PASWD_SUCCESS,
 
  FORGOT_PASWD_ERROR,
  DOCTOR_APPROVE_STATUS,
  ERX_DATA_HTML,
  ERX_DATA_HTML_NO_DATA,
} from '../../shared/constants/ActionTypes';

const initialState = {
  patientcount: null,
  patientsearch: '',
  forgotpaswdsuccess: '',
  forgotpaswdmsg: '',
  forgotpaswderror: '',
  doctorApprovedStatus: 0,
  patientnames: [],
  erxhtmldata: [],
  erxhtmlnodata: '',
  list_patient_data: null,
  list_patient_count: null,
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    //Assign user data
  
    case PATIENT_COMPLETED_PATIENT_NAME:
      return {
        ...state,
        patientnames: action.payload,
      };
    
    case FORGOT_PASWD_SUCCESS:
      return {
        ...state,
        forgotpaswdsuccess: action.payload,
      };
     
    case FORGOT_PASWD_ERROR:
      return {
        ...state,
        forgotpaswderror: action.payload,
      };
    case DOCTOR_APPROVE_STATUS:
      return {
        ...state,
        doctorApprovedStatus: action.payload,
      };
    
      case ERX_DATA_HTML:
        return {
          ...state,
          erxhtmldata: action.payload,
        };
        case ERX_DATA_HTML_NO_DATA:
          return {
            ...state,
            erxhtmlnodata: action.payload,
          };
    default:
      return state;
  }
};
export default patientReducer;
