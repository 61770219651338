/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route for dahboard page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const pharmacyConfigs = [
  {
    auth: authRole.userPhar,
    routes: [
      {
        path: '/pharmacy',
        component: React.lazy(() =>
          import('../pharmAssistPage/pharmacy/index'),
        ),
      },
    ],
  },
];
