/************ 
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route page for signup
 * Dependicies : To use @material-ui/core,react-router-dom,formik 
 ************/
import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./Signin/index')),
      },
    ],
  },
  
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/resetpassword',
        component: React.lazy(() => import('./ResetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/passwordRequest',
        component: React.lazy(() => import('./ResetpasswordRequest')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-403',
        component: React.lazy(() => import('../errorPages/Error403/index')),
      },
    ],
  },
];
