/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 * Dependicies : To use @material-ui/core
 ************/

import {authRole} from '../shared/constants/AppConst';
// const localRoleId = localStorage.getItem('roleId');

const routesConfig = [
  {
    id: 'dashbaord',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/dashboard',
  },
  {
    id: 'pharmacy',
    title: 'Pharmacy',
    messageId: 'sidebar.pharmacy',
    type: 'single',
    icon: 'medicine',
    auth: authRole.pharmacyRoles,
    url: '/pharmacy',
  },
  {
    id: 'labAssist',
    title: 'labAssist',
    messageId: 'sidebar.pharmacy',
    type: 'single',
    icon: 'medicine',
    auth: authRole.labRoles,
    url: '/labAssist',
  },
  {
    id: 'radioAssist',
    title: 'radioAssist',
    messageId: 'sidebar.pharmacy',
    type: 'single',
    icon: 'medicine',
    auth: authRole.radiologyRoles,
    url: '/radioAssist',
  },
];
export default routesConfig;
