/************

 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers

 ************/
import {
  STATE_MASTER,
  DOCTOR_QUALIFICATION,
  DOCTOR_SPECIALIZATION,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_PROFILE,
  SAVE_PROFILES,
  UPDATE_SETTINGS,
} from '../../shared/constants/ActionTypes';

const initialState = {
  stateMaster: [],
  specializationMaster: [],
  subSpecializationMaster: [],
  qualificationMaster: [],
  profileDatas: {},
  saveProfile: false,
  updatesettings: false,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATE_MASTER:
      return {
        ...state,
        stateMaster: action.payload,
      };
    case DOCTOR_QUALIFICATION:
      return {
        ...state,
        qualificationMaster: action.payload,
      };
    case DOCTOR_SPECIALIZATION:
      return {
        ...state,
        specializationMaster: action.payload,
      };
    case DOCTOR_SUBSPECIALIZATION:
      return {
        ...state,
        subSpecializationMaster: action.payload,
      };
    case DOCTOR_PROFILE:
      return {
        ...state,
        profileDatas: action.payload,
      };
    case SAVE_PROFILES:
      return {
        ...state,
        saveProfile: action.payload,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        updatesettings: action.payload,
      };
    default:
      return state;
  }
};
export default profileReducer;
