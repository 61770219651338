import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  PATIENT_SIGNUP,
  OTP_SUCCESS,
  OTP_SUCCESS_MSG,
  OTP_ERROR,
  UPDATE_AUTH_USER,
  AUTH_HOSPITAL,
  AUTH_CLIENT_FLOOR,
  AUTH_CLIENT_ROOM,
  SET_MOBILENO,
  SET_PASWD,
  OTP_SIGNUP_ERROR,
  SIGNIN_ERROR,
  OTP_UPDTE_SUCCESS,
  OTP_UPDTE_ERROR,
  VENDOR_API_DATA,
  AUTH_ERROR,
  AUTH_USERID,
  CHECK_MODE,
  DOCTOR_RESET
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  user: null,
  token: null,
  mobileno: null,
  paswd: null,
  signupstatus: '',
  otpsuccess: false,
  otpsuccessmsg: '',
  otperror: '',
  authHospital:[],
  clientRoom:[],
  clientFloor:[],
  otpsignuperror: '',
  signinerror: '',
  otpupdateerror: '',
  otpupdatesuccess: false,
  vendordata:{},
  auth_userId:'',
  auth_error:'',
  checkMode:'',
  checkMode:'',
  doctorReset:''
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case VENDOR_API_DATA: {
      return {
        ...state,
        vendordata: action.payload,
      };
    }
    case DOCTOR_RESET: {
      return {
        ...state,
        doctorReset: action.payload,
      };
    }
     case CHECK_MODE: {
      return {
        ...state,
        checkMode: action.payload,
      };
    }
    case AUTH_HOSPITAL: {
      return {
        ...state,
        authHospital: action.payload,
      };
    }
    case AUTH_CLIENT_FLOOR: {
      return {
        ...state,
        clientFloor: action.payload,
      };
    }
    case AUTH_CLIENT_ROOM: {
      return {
        ...state,
        clientRoom: action.payload,
      };
    }
    case AUTH_ERROR: {
      return {
        ...state,
        auth_error: action.payload,
      };
    }
    case AUTH_USERID: {
      return {
        ...state,
        auth_userId: action.payload,
      };
    }
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case SET_MOBILENO: {
      return {
        ...state,
        mobileno: action.payload,
      };
    }
    case SET_PASWD: {
      return {
        ...state,
        paswd: action.payload,
      };
    }
    case PATIENT_SIGNUP: {
      return {
        ...state,
        signupstatus: action.payload,
      };
    }
    case OTP_SUCCESS: {
      return {
        ...state,
        otpsuccess: action.payload,
      };
    }
    case OTP_SUCCESS_MSG: {
      return {
        ...state,
        otpsuccessmsg: action.payload,
      };
    }
    case OTP_ERROR: {
      return {
        ...state,
        otperror: action.payload,
      };
    }
    case OTP_SIGNUP_ERROR: {
      return {
        ...state,
        otpsignuperror: action.payload,
      };
    }
    case OTP_UPDTE_ERROR: {
      return {
        ...state,
        otpupdateerror: action.payload,
      };
    }
    case OTP_UPDTE_SUCCESS: {
      return {
        ...state,
        otpupdatesuccess: action.payload,
      };
    }
    case SIGNIN_ERROR: {
      return {
        ...state,
        signinerror: action.payload,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
